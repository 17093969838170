/* Built In Imports */
import { usePathname } from 'next/navigation';

/* External Imports */
import { Box, Text } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import FaqContentsSt from '@ieo/Common/StyleData/FaqContentsSt';
import RenderContent from '@ieo/Sections/RenderContent';

/* Services */

/**
 * Renders the FAQ content component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region for the section
 * @param {string} language - Language for the section
 * @returns {ReactElement} FAQ content component
 */

const FaqContents = ({ sectionContent, region, lang }) => {
  const path = usePathname();
  const url = path.includes('offer/under-25s');
  const checkV5Version = path.includes('v5')

  return (
    <Box
      position={url && 'relative'}
      _after={{
        content: "''",
        right: '0',
        bottom: '0',
        zIndex: '-1',
        pos: 'absolute',
        display: { base: 'none', md: url && 'block' },
        height: { base: '180px', md: '380px' },
        w: { base: '100%', md: '40%' },
        backgroundSize: 'contain',
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          'https://static.sadhguru.org/d/46272/1710580690-5-copy-2-3.png',
      }}
    >
      <Box
        as="div"
        marginTop="30px"
        pb={
          url
            ? { base: '80px', md: checkV5Version ? '130px' : '60px' }
            : { base: '60px', md: checkV5Version ? '130px' : '60px' }
        }
        mb={!url && { base: '60px', md: '100px' }}
        mx="auto"
        px={{ base: '10px', lg: '0px', md: '0px', '2xl': '0px' }}
        maxWidth="1036px"
        pt={{
          base: '50px',
          xl:
            path.includes('inner-engineering/offer/scholarship-by-sza') ||
            path.includes('inner-engineering/offer/scholarship-by-theo')
              ? '50px'
              : '130px',
        }}
      >
        <Box as="div" px={{ base: '0px', md: '15px', lg: '0px' }} pb="20px">
          <Text {...FaqContentsSt[lang].stFaText}>
            {sectionContent[0].text}
          </Text>
          <Text {...FaqContentsSt[lang].stQText}>{sectionContent[1].text}</Text>
        </Box>
        <Box
          // mt="30px"
          m={{
            base: '30px 0px 0 0px',
            md: '0 15px 0 15px',
            lg: '30px 0px 0 0px',
          }}
        >
          {sectionContent[2]?.faqSections?.map(dataChild => {
            // count = count + 1;
            return (
              <RenderContent
                key={nanoid(2)}
                lang={lang}
                dataChild={dataChild}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default FaqContents;
